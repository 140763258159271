import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/global/seo';
import Layout from '../components/global/layout/Layout';
import InfoBox from '../components/global/info-box/InfoBox';
import FeaturedCustomers from '../components/home-page/featured-customers/FeaturedCustomers';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import StatsBox from '../components/global/stats-box/StatsBox';
import CTA from '../components/global/CTA/CTA';
import { Container } from '../components/global/customers/styles';
import * as S from '../components/demand-generation/styles';

const demandGenerationTemplate = ({ data }) => {
    const {
        seoImage,
        seoTitle,
        seoDescription,
        seoArticle,
        section1Image,
        section1Title,
        section1Subtitle,
        section1Button,
        section2Image,
        section3Image,
        section4Image,
        section5Title,
        section6Image,
        section7Image,
        section8Stats,
        ctaTitle,
        seoJsonSchema,
    } = data.contentfulDemandGeneration;
    return (
        <Layout>
            <SEO
                title={seoTitle}
                description={seoDescription}
                image={seoImage}
                article={seoArticle}
                seoJsonSchema={seoJsonSchema}
            />
            <WrapperOne>
                <S.HeroWrapper>
                    <InfoBox
                        image={section1Image}
                        title={section1Title}
                        subtitle={section1Subtitle}
                        hideImage="true"
                        buttonText={section1Button?.linkText}
                        buttonLink={section1Button?.link}
                        videoBorderRadius={'10px'}
                        isHeroHeader
                    />
                </S.HeroWrapper>
            </WrapperOne>
            <S.SectionImageWrapper>
                <img src={section2Image.file.url} alt={section2Image.title} />
            </S.SectionImageWrapper>
            <Container>
                <S.SectionWrapper>
                    <InfoBox
                        image={section3Image}
                        title={section3Image.title}
                        subtitle={section3Image.description}
                        invert="true"
                        subHeading="true"
                        videoBorderRadius={'10px'}
                    />
                </S.SectionWrapper>
                <S.SectionWrapper>
                    <InfoBox
                        image={section4Image}
                        title={section4Image.title}
                        subtitle={section4Image.description}
                        subHeading="true"
                        videoBorderRadius={'10px'}
                    />
                </S.SectionWrapper>
                <FeaturedCustomers
                    section10Title={section5Title}
                    customers={data.allContentfulCustomers.nodes}
                />
                <S.SectionWrapper>
                    <InfoBox
                        image={section6Image}
                        title={section6Image.title}
                        subtitle={section6Image.description}
                        invert="true"
                        subHeading="true"
                        videoBorderRadius={'10px'}
                    />
                </S.SectionWrapper>
                <S.SectionWrapper>
                    <InfoBox
                        image={section7Image}
                        title={section7Image.title}
                        subtitle={section7Image.description}
                        subHeading="true"
                        videoBorderRadius={'10px'}
                    />
                </S.SectionWrapper>
            </Container>
            <S.StatsWrapper>
                <StatsBox data={section8Stats.name} title={section8Stats.title} />
            </S.StatsWrapper>
            <S.CTAWrapper>
                <Container>
                    <CTA title={ctaTitle} />
                </Container>
            </S.CTAWrapper>
        </Layout>
    );
};

export const pageQuery = graphql`
	query($slug: String, $relatedCustomerSlug: [String]) {
		contentfulDemandGeneration(slug: { eq: $slug }) {
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			section1Image {
				file {
					url
					contentType
				}
			}
			section1Title
			section1Subtitle
			section1Button {
				link
				linkText
			}
			section2Image {
				title
				file {
					url
				}
			}
			section3Image {
				title
				description
				file {
					url
					contentType
				}
			}
			section4Image {
				title
				description
				file {
					url
					contentType
				}
			}
			section5Title
			section6Image {
				title
				description
				file {
					url
					contentType
				}
			}
			section7Image {
				title
				description
				file {
					url
					contentType
				}
			}
			section8Stats {
				title
				name {
					content
					title
				}
			}
			ctaTitle
			seoJsonSchema {
				internal {
					content
				}
			}
		}
        allContentfulCustomers(
			filter: { slug: { in: $relatedCustomerSlug } }
			sort: { order: ASC, fields: id }
		) {
			nodes {
				title
				slug
				cardImage {
					fluid(maxWidth: 234, maxHeight: 234) {
						...GatsbyContentfulFluid
					}
					title
				}
				cardLogo {
					fixed(width: 200) {
						...GatsbyContentfulFixed
					}
					title
				}
			}
		}
	}
`;

export default demandGenerationTemplate;
